import React, { Fragment, useCallback, useMemo } from 'react'
import SkeletonLoader from '@components/UI/SkeletonLoader'

import { UserAccountEmail, UserAccountPhoneNumber } from '@graphql/graphql'

import { Button, Icon, theme } from '@damen/ui'

import Card from '@components/UI/Card'
import Container from '@components/Container/Container'
import { FormTitle } from '@components/UI/Forms/FormHeader'
import {
	FormDescription,
	FormDivider,
	FormSummaryList,
	FormWrapper,
	SummaryHeader,
	StyledSpacer,
	FormText
} from '@components/UI/Forms/FormUI'

import {
	MySettingForms,
	MyUserAccountCompany,
	OverviewSummaryProps,
	UserAccountItem
} from '@components/MySettings/types'

import ProfileInfo from '../ProfileInfo'

import { Block, SupportEmail } from './styles'

const OverviewSummary: React.FC<OverviewSummaryProps> = ({
	authenticationTitle,
	authenticationDescription,
	labelAuthenticationAccessAccount,
	labelAuthenticationAuthorization,
	labelEdit,
	labelPersonalDescription,
	labelPersonalDetailsCompany,
	labelPersonalDetailsEmail,
	labelPersonalDetailsJobTitle,
	labelPersonalDetailsName,
	labelPersonalDetailsPhone,
	labelPreferencesLanguage,
	labelPreferencesContact,
	labelCaseCreatedNotification,
	labelCaseClosedNotification,
	labelCaseCommentCreatedNotification,
	notificationEnableTrueText,
	notificationEnableFalseText,
	needHelpTitle,
	personalDetailsTitle,
	preferencesTitle,
	onEdit,
	myUserAccount,
	userAccounts = { items: [] },
	helpWithAdminsText,
	helpWithoutAdminsText,
	helpWithoutAdminsEmail
}) => {
	// Event handlers
	const editPersonalDetailsClickHandler = useCallback(() => {
		if (onEdit) {
			onEdit(MySettingForms.PERSONAL_DETAILS)
		}
	}, [onEdit])

	const editPreferencesClickHandler = useCallback(() => {
		if (onEdit) {
			onEdit(MySettingForms.PREFERENCES)
		}
	}, [onEdit])

	// Cached values
	const FullName = useMemo(() => {
		if (myUserAccount?.name?.fullName) {
			return myUserAccount?.name?.title
				? `${myUserAccount?.name?.title} ${myUserAccount?.name?.fullName}`
				: myUserAccount?.name?.fullName
		}
		return <SkeletonLoader width="50%" height={21} />
	}, [myUserAccount])

	const PhoneNumbers = useMemo(() => {
		if (myUserAccount?.phoneNumbers?.length > 0) {
			return myUserAccount.phoneNumbers.map(
				(item: UserAccountPhoneNumber) => (
					<Fragment key={item.phonenumber}>
						<Block>{item.phonenumber}</Block>
					</Fragment>
				)
			)
		}
		if (
			myUserAccount?.phoneNumbers === null ||
			myUserAccount?.phoneNumbers?.length === 0
		) {
			return null
		}
		return <SkeletonLoader width="60%" height={21} />
	}, [myUserAccount])

	const Email = useMemo(() => {
		if (myUserAccount?.emails) {
			return myUserAccount.emails.map((item: UserAccountEmail) => (
				<Fragment key={item.email}>
					<Block>{item.email}</Block>
				</Fragment>
			))
		}
		return <SkeletonLoader width="80%" height={21} />
	}, [myUserAccount])

	const AccessLevel = useMemo(() => {
		if (myUserAccount?.accessRight) {
			return (
				<>
					<b>{myUserAccount?.accessRight?.name} </b>
					<Block>{myUserAccount?.accessRight?.description}</Block>
				</>
			)
		}
		return <SkeletonLoader width="80%" height={21} count={1} />
	}, [myUserAccount])

	const PreferredLanguage = useMemo(() => {
		if (myUserAccount?.notificationSettings?.preferredLanguage?.name) {
			return (
				<>{myUserAccount.notificationSettings.preferredLanguage.name}</>
			)
		}
		return <SkeletonLoader width="60%" height={21} />
	}, [myUserAccount])

	const SetNotificationText = useCallback(
		(notificationEnabled: boolean) => {
			return notificationEnabled
				? notificationEnableTrueText
				: notificationEnableFalseText
		},
		[notificationEnableFalseText, notificationEnableTrueText]
	)

	const CaseCreatedNotification = useMemo(() => {
		if (myUserAccount?.notificationSettings) {
			return (
				<>
					{SetNotificationText(
						myUserAccount?.notificationSettings?.createCase
					)}
				</>
			)
		}
		return <SkeletonLoader width="60%" height={21} />
	}, [SetNotificationText, myUserAccount?.notificationSettings])

	const CaseClosedNotification = useMemo(() => {
		if (myUserAccount?.notificationSettings) {
			return (
				<>
					{SetNotificationText(
						myUserAccount?.notificationSettings
							?.sendCaseClosureEmail
					)}
				</>
			)
		}
		return <SkeletonLoader width="60%" height={21} />
	}, [SetNotificationText, myUserAccount?.notificationSettings])

	const CaseCommentCreatedNotification = useMemo(() => {
		if (myUserAccount?.notificationSettings) {
			return (
				<>
					{SetNotificationText(
						myUserAccount?.notificationSettings?.createCaseComment
					)}
				</>
			)
		}
		return <SkeletonLoader width="60%" height={21} />
	}, [SetNotificationText, myUserAccount?.notificationSettings])

	const isLoading = myUserAccount?.name === undefined

	const enablePreferance = true
	const enableNeedHelp = false

	return (
		<StyledSpacer>
			<Container small>
				<Card>
					<FormWrapper>
						<SummaryHeader>
							<div>
								<FormTitle>
									{personalDetailsTitle ??
										'[Personal details]'}
								</FormTitle>
								{labelPersonalDescription && (
									<FormDescription>
										{labelPersonalDescription}
									</FormDescription>
								)}
							</div>
							<Button.Default
								buttonType="text"
								icon={Icon.Edit}
								iconPosition="left"
								text={labelEdit}
								iconSize={18}
								onClick={editPersonalDetailsClickHandler}
							/>
						</SummaryHeader>
						<div>
							<FormSummaryList>
								{labelPersonalDetailsName && (
									<>
										<dt>
											{labelPersonalDetailsName ??
												'[Name]'}
										</dt>
										<dd>{FullName}</dd>
									</>
								)}
								{PhoneNumbers && labelPersonalDetailsPhone && (
									<>
										<dt>
											{labelPersonalDetailsPhone ??
												'[Phone numbers]'}
										</dt>
										<dd>{PhoneNumbers}</dd>
									</>
								)}
								{labelPersonalDetailsEmail && (
									<>
										<dt>
											{labelPersonalDetailsEmail ??
												'[E-mail address]'}
										</dt>
										<dd>{Email}</dd>
									</>
								)}
								{isLoading ? (
									<>
										<dt>
											{labelPersonalDetailsJobTitle ??
												'[Job title]'}
										</dt>
										<dd>
											<SkeletonLoader
												width="50%"
												height={21}
											/>
										</dd>
										<dt>
											{labelPersonalDetailsCompany ??
												'[Company]'}
										</dt>
										<dd>
											<SkeletonLoader
												width="50%"
												height={21}
											/>
										</dd>
									</>
								) : (
									<>
										{labelPersonalDetailsJobTitle &&
											myUserAccount.work.jobTitle && (
												<>
													<dt>
														{labelPersonalDetailsJobTitle ??
															'[Job title]'}
													</dt>
													<dd>
														{
															myUserAccount.work
																.jobTitle
														}
													</dd>
												</>
											)}
										{labelPersonalDetailsCompany &&
											myUserAccount.mainCompany?.name && (
												<>
													<dt>
														{labelPersonalDetailsCompany ??
															'[Company]'}
													</dt>
													<dd>
														{
															myUserAccount
																.mainCompany
																?.name
														}
													</dd>
												</>
											)}
									</>
								)}
							</FormSummaryList>
						</div>
					</FormWrapper>
				</Card>
				{enablePreferance && (
					<Card>
						<FormWrapper>
							<SummaryHeader>
								<FormTitle>
									{preferencesTitle ?? '[Preferences]'}
								</FormTitle>
								<Button.Default
									buttonType="text"
									icon={Icon.Edit}
									iconPosition="left"
									text={labelEdit}
									iconSize={18}
									onClick={editPreferencesClickHandler}
								/>
							</SummaryHeader>
							<div>
								<FormSummaryList>
									{labelPreferencesLanguage && (
										<>
											<dt>
												{labelPreferencesLanguage ??
													'[Language used in the portal]'}
											</dt>
											<dd>{PreferredLanguage}</dd>
										</>
									)}
									{labelCaseCreatedNotification && (
										<>
											<dt>
												{labelCaseCreatedNotification}
											</dt>
											<dd>{CaseCreatedNotification}</dd>
										</>
									)}
									{labelCaseClosedNotification && (
										<>
											<dt>
												{labelCaseClosedNotification}
											</dt>
											<dd>{CaseClosedNotification}</dd>
										</>
									)}
									{labelCaseCommentCreatedNotification && (
										<>
											<dt>
												{
													labelCaseCommentCreatedNotification
												}
											</dt>
											<dd>
												{CaseCommentCreatedNotification}
											</dd>
										</>
									)}
								</FormSummaryList>
							</div>
						</FormWrapper>
					</Card>
				)}
				<Card>
					<FormWrapper>
						<SummaryHeader>
							<FormTitle>
								{authenticationTitle ?? '[Login and security]'}
							</FormTitle>
						</SummaryHeader>
						<FormText>
							{authenticationDescription ??
								'[All fields in this section are mandatory.]'}
						</FormText>
						<div>
							<FormSummaryList>
								{labelAuthenticationAccessAccount && (
									<>
										<dt>
											{labelAuthenticationAccessAccount ??
												'[Access to account of the company:]'}
										</dt>
										<dd>
											{myUserAccount?.companies?.map(
												(
													company: MyUserAccountCompany
												) => (
													<Block key={company.id}>
														{company.name}
													</Block>
												)
											) ?? (
												<SkeletonLoader
													width="50%"
													height={21}
													count={3}
												/>
											)}
										</dd>
									</>
								)}
								{labelAuthenticationAuthorization && (
									<>
										<dt>
											{labelAuthenticationAuthorization ??
												'[Authorisation level]'}
										</dt>
										<dd>{AccessLevel}</dd>
									</>
								)}
							</FormSummaryList>
						</div>
					</FormWrapper>
					{enableNeedHelp && <FormDivider hasNoTopMargin={false} />}
					{enableNeedHelp && (
						<FormWrapper>
							<SummaryHeader>
								<FormTitle>
									{needHelpTitle ?? '[Need help]'}
								</FormTitle>
							</SummaryHeader>
							<FormText>
								{userAccounts?.items?.length > 0 &&
									(helpWithAdminsText ??
										'[If there is a mistake or you need your authentication or rights changed, get in touch with the admins of your company.]')}
								{userAccounts?.items?.length === 0 &&
									(helpWithoutAdminsText ??
										'[If there is a mistake or you need your authentication or rights changed, get in touch with the support team of My Damen.]')}
							</FormText>
							<FormWrapper>
								{userAccounts?.items?.length > 0 &&
									userAccounts.items.map(
										(item: UserAccountItem) => (
											// Should NeedHelp be enabled this should be replaced with the ContactDetails component
											<ProfileInfo
												key={item.id}
												name={item.name.fullName}
												imageSrc=""
												label={labelPreferencesContact}
												email={
													item?.emails?.length > 0
														? item.emails[0].email
														: null
												}
											/>
										)
									)}
								{userAccounts?.items?.length === 0 && (
									<>
										<Icon.EnvelopeRegular
											height={16}
											width={17}
											fill={theme.colors.blue}
										/>
										<SupportEmail
											href={`mailto:${helpWithoutAdminsEmail}`}
										>
											{helpWithoutAdminsEmail ??
												'[myaccount@damen.com]'}
										</SupportEmail>
									</>
								)}
							</FormWrapper>
						</FormWrapper>
					)}
				</Card>
			</Container>
		</StyledSpacer>
	)
}

export default OverviewSummary
