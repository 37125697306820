import { Asset, GetAssetOverviewQuery } from '@graphql/graphql'
import { AssetOverviewStoryblok } from '@graphql/storyblokcomponents'

export type GoToDetailFnAsset = (n: Pick<Asset, 'id'>) => any

export type AssetData = GetAssetOverviewQuery['assets']['items']

export enum AssetColumnKeys {
	vessel = 'vessel',
	yardNo = 'yardNo',
	type = 'type',
	warranty = 'warranty',
	deliveryDateFormatted = 'deliveryDateFormatted',
	favourite = 'favourite'
}

export interface AssetOverviewProps {
	blok: AssetOverviewStoryblok
}

export interface AssetFilters {
	vesselTypes?: string[]
}
