import styled from 'styled-components'
import { theme } from '@damen/ui'
import Table from '@components/Table'

export const Title = styled.h2`
	font-size: ${theme.typography.fontSizeHeadingTwo}px;
	line-height: ${theme.typography.lineHeightHeadingTwo};
	color: ${theme.colors.marineBlack};
`

export const FavouriteButton = styled.button`
	background: transparent;
	border: 0;
	cursor: pointer;
`

export const StyledTable = styled(Table)`
	@media ${theme.breakpoints.md} {
		thead th:last-child {
			opacity: 0;
		}
	}
`

export const OverviewWrapper = styled.div`
	@media ${theme.breakpoints.lg} {
		padding-top: 80px;
	}
`
