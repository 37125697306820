import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState
} from 'react'
import {
	ApolloClient,
	NormalizedCacheObject,
	makeVar,
	useReactiveVar
} from '@apollo/client'
import { TabControl, theme } from '@damen/ui'
import {
	GetDashboardPageDataDocument,
	GetDashboardPageDataQuery,
	GetServiceRequestPageDataDocument,
	GetServiceRequestPageDataQuery,
	PageItem,
	useGetCustomerQuery,
	useGetServiceRequestStatusItemsQuery
} from '@graphql/graphql'

import { useHeaderContext } from '@contexts/HeaderContext'

import Loading from '@components/Loading'
import Sidebar from '@components/Sidebar'
import StatusExplanation from '@components/UI/StatusExplanation'
import ErrorState from '@components/UI/Error'

import StatusExplanationSkeleton from '@skeletons/StatusExplanation'

import useWindowSize from '@hooks/useWindowSize'

import { useRouter } from 'next/router'
import { toggleScroll } from '@utils/scrollLock'

import { DESKTOP_DASHBOARD_GAP } from '@src/constants/Viewports'
import useError from '@hooks/useError'
import { Content } from '@components/UI/StatusExplanation/styles'
import { useLocale } from '@hooks/useLocale'
import { getStartsFromLocale } from '@utils/getStartsFromLocale'
import {
	Background,
	Image,
	ImageWrapper,
	LoadingWrapper,
	Name,
	Wrapper
} from './styles'
import ServiceRequestsDashboard from './ServiceRequestsDashboard'
import ServiceRequestsLatestRequests from './ServiceRequestsLatestRequests'
import AppsAndLinks from './AppsAndLinks'
import { getDashboardBlok, getGreeting } from './translationHelper'

export async function GetPageItem(
	apolloClient: ApolloClient<NormalizedCacheObject>,
	locale: string
) {
	const query1 = apolloClient.query<GetDashboardPageDataQuery>({
		query: GetDashboardPageDataDocument,
		variables: {
			language: locale
		}
	})

	const query2 = apolloClient.query<GetServiceRequestPageDataQuery>({
		query: GetServiceRequestPageDataDocument,
		variables: {
			language: locale
		}
	})

	const results = await Promise.all([query1, query2])

	return results[0].data.PageItem ?? {}
}

const shownDashboardTransition = makeVar<boolean>(false)

const Dashboard = ({ pageData }: { pageData: PageItem }) => {
	const { showError } = useError()
	const { width } = useWindowSize()
	const router = useRouter()
	const { locale } = useLocale()
	if (router.asPath === '/dashboard') {
		router.replace({ pathname: '/' })
	}

	const hasShownDashboardTransition = useReactiveVar(shownDashboardTransition)
	const dashboardBlok = useMemo(() => getDashboardBlok(pageData), [pageData])
	const statusSidePanelTitle = useMemo(
		() =>
			pageData?.content?.sidebarBlocks?.filter(
				(item) => item.component === 'serviceRequestStatusSidebar'
			)?.[0]?.title,
		[pageData]
	)

	const statusSidePanelErrorDescription = useMemo(
		() =>
			pageData?.content?.sidebarBlocks?.filter(
				(item) => item.component === 'serviceRequestStatusSidebar'
			)?.[0]?.errorDescription,
		[pageData]
	)

	const { setHeaderColor, setLogoSrc, setShowNavigationBar } =
		useHeaderContext()

	const [backgroundX, setBackgroundX] = useState(0)
	const [loading, setLoading] = useState(true)
	const [animating, setAnimating] = useState(!hasShownDashboardTransition)
	const [showLoader, setShowLoader] = useState(true)
	const [showStatusPanel, setShowStatusPanel] = useState(false)

	const {
		data,
		loading: customerLoading,
		error: customerError
	} = useGetCustomerQuery({
		fetchPolicy: 'no-cache'
	})

	const customerInfo = {
		name: data?.myUserAccount?.name?.firstName,
		imageSrc: data?.customer?.images?.companyImage
	}

	const {
		data: serviceRequestStatuses,
		loading: serviceRequestStatusesLoading,
		error: serviceRequestStatusesError
	} = useGetServiceRequestStatusItemsQuery({
		variables: {
			language: locale,
			starts_with: getStartsFromLocale(locale)
		}
	})

	const imageLoadHandler = useCallback(() => {
		setLoading(false)

		// Hide loader after 600ms
		setTimeout(() => {
			setShowLoader(false)
		}, theme.timing.numDefault)

		// Disable animation after 1800ms
		setTimeout(() => {
			setAnimating(false)
			shownDashboardTransition(true)
			setShowNavigationBar(true)
		}, theme.timing.numSlow * 5)
	}, [setAnimating, setLoading, setShowLoader, setShowNavigationBar])

	const imageErrorHandler = useCallback(() => {
		showError()
	}, [showError])

	// Cached animation props based upon screen size
	const imageAnimateProps = useMemo(() => {
		return width < theme.breakpointsNumerical.md
			? {
					x: '0%',
					y: loading ? '0%' : '-100%',
					scale: 1
			  }
			: {
					x: loading ? '0%' : backgroundX,
					y: '0%',
					scale: loading ? 1 : 1.25
			  }
	}, [backgroundX, loading, width])

	const backgroundInitialProps = useMemo(() => {
		return width < theme.breakpointsNumerical.md
			? { y: '100%', x: '0%' }
			: { x: '100%', y: '0%' }
	}, [width])

	const backgroundAnimateProps = useMemo(() => {
		return width < theme.breakpointsNumerical.md
			? { y: loading ? '100%' : '0%', x: '0%' }
			: { x: loading ? '100%' : '0%', y: '0%' }
	}, [loading, width])

	useEffect(() => {
		if (!customerLoading && !customerError && data) {
			setLogoSrc(data?.customer?.images?.companyLogo)
		}
	}, [customerError, customerLoading, data, setLogoSrc])

	useEffect(() => {
		if (customerError) {
			showError()
		}
	}, [customerError, showError])

	// Set background x position (end position on resize)
	useLayoutEffect(() => {
		setBackgroundX(-(width * 0.5) + DESKTOP_DASHBOARD_GAP)
	}, [width, setBackgroundX])

	useEffect(() => {
		setHeaderColor('transparent')
	}, [setHeaderColor])

	useEffect(() => {
		if (!animating) {
			setHeaderColor('white')
		}
	}, [setHeaderColor, animating])

	useEffect(() => {
		toggleScroll(width >= theme.breakpointsNumerical.md)
	}, [width])

	useEffect(() => {
		setShowNavigationBar(hasShownDashboardTransition)
	}, [hasShownDashboardTransition, setShowNavigationBar])

	const [activeTab, setActiveTab] = useState<
		'serviceRequests' | 'quickLinks'
	>('serviceRequests')

	const iconClickHandler = useCallback(() => {
		setShowStatusPanel(true)
	}, [setShowStatusPanel])

	const items = serviceRequestStatuses?.ServicerequeststatusItems?.items

	return (
		dashboardBlok && (
			<>
				<Sidebar
					isOpen={showStatusPanel}
					onClose={() => setShowStatusPanel(false)}
				>
					{serviceRequestStatusesError &&
						!serviceRequestStatusesLoading && (
							<Content>
								<ErrorState
									content={
										statusSidePanelErrorDescription ??
										'Something went wrong while retrieving service request statuses information.'
									}
									showBowWave
								/>
							</Content>
						)}
					{serviceRequestStatusesLoading &&
						!serviceRequestStatusesError && (
							<StatusExplanationSkeleton rows={3} />
						)}
					{!serviceRequestStatusesError &&
						!serviceRequestStatusesLoading &&
						items?.length > 0 && (
							<StatusExplanation
								items={items}
								title={
									statusSidePanelTitle ??
									'[Status Explanation]'
								}
							/>
						)}
				</Sidebar>
				<Wrapper>
					{customerInfo.imageSrc && (
						<ImageWrapper
							animate={imageAnimateProps}
							transition={
								animating
									? { delay: 2.5, duration: 1.5 }
									: { delay: 0, duration: 0 }
							}
						>
							<Image
								animate={{
									opacity: 1
								}}
								transition={{
									duration: theme.timing.numDefault / 1000
								}}
								src={customerInfo.imageSrc}
								onLoad={imageLoadHandler}
								onError={imageErrorHandler}
							/>
						</ImageWrapper>
					)}
					<Background
						initial={backgroundInitialProps}
						animate={backgroundAnimateProps}
						transition={
							animating
								? { delay: 2.5, duration: 1.5 }
								: { delay: 0, duration: 0 }
						}
					>
						<Name>
							{getGreeting(
								dashboardBlok.labelGreeting,
								customerInfo.name
							)}
						</Name>
						{dashboardBlok.labelGreetingSub}
						<TabControl.Default
							defaultIndex={0}
							tabcontrol={{
								tabs: [
									{
										id: 'serviceRequests',
										title: dashboardBlok.labelTabRecentItems,
										onClick: () =>
											setActiveTab('serviceRequests')
									},
									{
										id: 'quickLinks',
										title: dashboardBlok.labelTabQuickLinks,
										onClick: () =>
											setActiveTab('quickLinks')
									}
								]
							}}
							lineColor="marineBlack"
						/>
						{activeTab === 'serviceRequests' && (
							<>
								<ServiceRequestsDashboard
									content={dashboardBlok}
									iconClickHandler={iconClickHandler}
								/>

								<ServiceRequestsLatestRequests
									content={dashboardBlok}
									iconClickHandler={iconClickHandler}
								/>

								{/** TODO: Messages table */}
							</>
						)}
						{activeTab === 'quickLinks' && (
							<AppsAndLinks content={dashboardBlok} />
						)}
					</Background>
				</Wrapper>
				{showLoader && (
					<LoadingWrapper>
						<Loading.Default
							initial={{ opacity: 1 }}
							animate={{ opacity: loading ? 1 : 0 }}
							transition={{
								duration: theme.timing.numDefault / 1000
							}}
						/>
					</LoadingWrapper>
				)}
			</>
		)
	)
}

export default Dashboard
