import styled from 'styled-components'

import { theme } from '@damen/ui'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${theme.colors.blueIce};
	padding-bottom: 38px;
`

export const CardSummaryContainer = styled.div`
	@media ${theme.breakpoints.md} {
		display: flex;
		flex-direction: row;
		margin: 0 -20px;
	}

	@media ${theme.breakpoints.lg} {
		margin: 0 -30px;
	}
`

export const CardSummaryGridContainer = styled.div`
	@media ${theme.breakpoints.md} {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin: 0 -20px;
	}

	@media ${theme.breakpoints.lg} {
		margin: 0 -30px;
	}
`
