import React, { FC } from 'react'
import { FieldArray } from 'formik'

import Checkbox from '@damen/ui/lib/cjs/components/Form/Checkbox'

import { FormLabel } from '@components/UI/Forms/FormUI'

import { CheckboxGroupError } from './styles'
import { FieldCheckboxGroupProps, CheckboxItem } from './types'

const FieldCheckboxGroup: FC<FieldCheckboxGroupProps> = ({
	hideLabel = false,
	label,
	name,
	options
}) => (
	<FieldArray
		name={name}
		render={({ push, remove, form: { errors, values, setFieldValue } }) => (
			<>
				{label && !hideLabel && (
					<FormLabel htmlFor={name}>{label}</FormLabel>
				)}
				{options?.map((item: CheckboxItem) => (
					<Checkbox
						key={item.key}
						type="checkbox"
						background="blue"
						name={name}
						label={item.value}
						checked={values[name].includes(item.key)}
						onChange={(event) => {
							if (event.target.checked) {
								push(item.key)
								setFieldValue(name, [...values[name], item.key])
							} else {
								const idx = values[name].indexOf(item.key)
								remove(idx)
								setFieldValue(
									name,
									values[name].filter(
										(tag: string) => tag !== item.key
									)
								)
							}
						}}
					/>
				))}
				{errors[name] !== '' &&
					errors[name] !== undefined &&
					!hideLabel && (
						<CheckboxGroupError>{`${label} ${errors[name]}`}</CheckboxGroupError>
					)}
			</>
		)}
	/>
)

export default FieldCheckboxGroup
