import styled from 'styled-components'

import { theme } from '@damen/ui'
import ProfilePicture from '@components/UI/ProfilePicture'

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin: 20px 0;

	@media ${theme.breakpoints.md} {
		display: inline-flex;
		width: 50%;
	}
`

export const Image = styled(ProfilePicture)`
	flex-grow: 0;
	flex-shrink: 0;
`

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	margin-left: 32px;
	justify-content: center;

	button {
		font-size: 14px;
		line-height: 14px;
	}
`

export const Title = styled.h4`
	margin: 0 16px 4px 0;
`

export const JobTitle = styled.div`
	margin: 0 16px 8px 0;
	font-size: 14px;
	letter-spacing: 0.2px;
	line-height: 1.71;
`
