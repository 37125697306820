import React from 'react'
import Container from '@components/Container/Container'
import Spacer from '@components/Spacer'
import TwoColRow from '@components/TwoColRow'
import SearchSkeleton from '@skeletons/Search'
import TableSkeleton from '@skeletons/Table'
import PaginationSkeleton from '@skeletons/Pagination'
import FilterSkeleton from '@skeletons/Filter'
import { PaginationWrapper } from '@components/GenericOverview/styles'

const filterAmount = 3

const AssetOverviewSkeleton = () => (
	<Container>
		<TwoColRow
			left={<FilterSkeleton amount={filterAmount} />}
			right={<SearchSkeleton />}
		/>
		<Spacer>
			<TableSkeleton />
			<PaginationWrapper>
				<PaginationSkeleton />
			</PaginationWrapper>
		</Spacer>
	</Container>
)

export default AssetOverviewSkeleton
