import {
	UserAccountEmail,
	UserAccountPhoneNumber,
	UserAccountWork
} from '@graphql/graphql'
import { Validation } from '@components/UI/Forms/Validation/types'

export enum MySettingForms {
	PERSONAL_DETAILS = 'personal-details',
	PREFERENCES = 'preferences',
	PRIMARY_EMAIL = 'primary-email',
	PRIMARY_PHONE_NUMBER = 'primary-phone-number',
	TWO_FACTOR_AUTHENTICATION = 'two-factor-authentication'
}

export interface MySettingsProps {
	blok: OverviewSummaryProps
	validation: Validation
}

export interface OverviewSummaryProps {
	authenticationTitle: string
	authenticationDescription: string
	labelAuthenticationPrimaryEmail: string
	labelAuthenticationPrimaryPhone: string
	labelAuthenticationAccessAccount: string
	labelAuthenticationPassword: string
	labelAuthenticationAuthorization: string
	labelAuthenticationChange: string
	labelEdit: string
	labelPersonalDescription: string
	labelPersonalDetailsCompany: string
	labelPersonalDetailsDepartment: string
	labelPersonalDetailsEmail: string
	labelPersonalDetailsJobTitle: string
	labelPersonalDetailsName: string
	labelPersonalDetailsPhone: string
	labelPersonalDetailsPicture: string
	labelPreferencesEmail: string
	labelPreferencesFeedback: string
	labelPreferencesLanguage: string
	labelPreferencesUpdates: string
	labelPreferencesContact: string
	labelCaseCreatedNotification: string
	labelCaseClosedNotification: string
	labelCaseCommentCreatedNotification: string
	notificationEnableTrueText: string
	notificationEnableFalseText: string
	needHelpTitle: string
	personalDetailsTitle: string
	preferencesTitle: string
	onEdit?: (form: MySettingForms) => void
	myUserAccount: MyUserAccount | undefined
	userAccounts: UserAccounts | undefined
	helpWithAdminsText: string
	helpWithoutAdminsText: string
	helpWithoutAdminsEmail: string
	errorDescription: string
}

export interface AccessRight {
	name: string
	description: string
}

export interface AccountsName {
	title: string
	firstName: string
	fullName: string
	lastName: string
	middleName: string
}

export interface MyUserAccountCompany {
	id: string
	name: string
}

export interface MyUserAccount {
	accessRight: AccessRight
	avatar: string
	companies: MyUserAccountCompany[]
	work: UserAccountWork
	phoneNumbers?: UserAccountPhoneNumber[]
	emails: UserAccountEmail[]
	name: AccountsName
	notificationSettings: {
		sendCaseClosureEmail: boolean
		createCaseComment: boolean
		createCase: boolean
		preferredLanguage: {
			id: string
			name: string
		}
	}
	mainCompany: {
		id: string
		name: string
	}
}

export interface UserAccountItem {
	accessRight: AccessRight
	id: string
	name: AccountsName
	emails: UserAccountEmail[]
}

export interface UserAccounts {
	items: UserAccountItem[]
}

export interface MySettingDataProps {
	myUserAccount?: MyUserAccount | undefined
	userAccounts?: UserAccounts | undefined
}
