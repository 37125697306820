import React, { createContext, useMemo, useState } from 'react'
import {
	useGetAccessRightsQuery,
	useGetUserAccountQuery
} from '@graphql/graphql'

import { useLocale } from '@hooks/useLocale'
import { DxpForm } from '@components/UI/Forms/types'
import { validationSchema } from './Validation'

import { ReactivateUserAccountFormInitialValues } from './types'

export const ReactivateUserAccountStore = createContext<DxpForm>(null)

const ReactivateUserAccountProvider = ({ userAccountId, children }) => {
	const [title, setTitle] = useState('')

	const { locale } = useLocale()

	const {
		data: userAccount,
		loading: userAccountLoading,
		error: userAccountError
	} = useGetUserAccountQuery({
		fetchPolicy: 'no-cache',
		variables: {
			language: locale,
			id: userAccountId
		}
	})

	const {
		data: accessRights,
		loading: accessRightsLoading,
		error: accessRightsError
	} = useGetAccessRightsQuery({
		variables: {
			language: locale
		}
	})

	const initialValues: ReactivateUserAccountFormInitialValues =
		useMemo(() => {
			return {
				userAccountId,
				fullName: userAccount?.userAccount?.name?.fullName || '',
				email: userAccount?.userAccount?.emails[0].email || '',
				companies: userAccount?.userAccount?.companies,
				authorization: userAccount?.userAccount?.accessRight?.id ?? '',
				allAuthorization: accessRights?.accessRights || [],
				primaryCompany:
					userAccount?.userAccount?.primaryCompany?.name ?? 'Unknown'
			}
		}, [
			accessRights?.accessRights,
			userAccount?.userAccount?.accessRight?.id,
			userAccount?.userAccount?.companies,
			userAccount?.userAccount?.emails,
			userAccount?.userAccount?.name?.fullName,
			userAccount?.userAccount?.primaryCompany?.name,
			userAccountId
		])

	const context = useMemo(
		() => ({
			title,
			initialValues,
			setTitle,
			validationSchema,
			loading: userAccountLoading || accessRightsLoading,
			error: userAccountError || accessRightsError
		}),
		[
			title,
			initialValues,
			userAccountLoading,
			accessRightsLoading,
			userAccountError,
			accessRightsError
		]
	)

	return (
		<ReactivateUserAccountStore.Provider value={context}>
			{children}
		</ReactivateUserAccountStore.Provider>
	)
}

export default ReactivateUserAccountProvider
