import React, { Children, ReactElement } from 'react'
import styled from 'styled-components'

import { theme } from '@damen/ui'

import BarChartBackground from './BarChartBackground'

const Container = styled.div`
	padding: 16px;

	@media ${theme.breakpoints.md} {
		padding: 32px;
	}
`
const BarChartLegend = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 24px;
`
const BarChartLegendItem = styled.div<{ color: string }>`
	::before {
		content: '';
		display: inline-block;
		width: 12px;
		height: 12px;
		background: ${(props) => props.color};
		margin-right: 8px;
	}
`
const Chart = styled.div<{ highestValue: number }>`
	/* bar height + label height */
	height: calc(200px + 32px);
	position: relative;

	--highest-value: ${(props) => props.highestValue};
`
const StyledBarChartBackground = styled(BarChartBackground)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 200px;
`
const Bars = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	gap: 32px;
	justify-content: space-between;
	align-items: flex-end;
	margin: 0 auto;
	max-width: 284px;
`
const ChartBar = styled.div<{ value: number; color: string }>`
	--value: ${(props) => props.value};

	width: 32px;
	height: calc(var(--value) / var(--highest-value) * (100% - 32px));
	min-height: 2px;
	margin-bottom: 32px;
	background: ${(props) => props.color};
	position: relative;

	::after {
		/* The only way to use a number as a pseudo-element's content is to use a counter */
		counter-reset: variable var(--value);
		content: counter(variable);
		display: block;
		position: absolute;
		bottom: -16px;
		transform: translateY(100%);
		font-size: 16px;
		font-weight: 500;
		width: 100%;
		text-align: center;
	}
`

interface BarProps {
	// eslint-disable-next-line react/no-unused-prop-types
	label: string
	// eslint-disable-next-line react/no-unused-prop-types
	value: number
	// eslint-disable-next-line react/no-unused-prop-types
	color: string
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Bar = (props: BarProps) => null

interface Props {
	highestValue: number
	children: Array<ReactElement<BarProps>>
}

const BarChart = ({ highestValue, children, ...others }: Props) => (
	<Container {...others}>
		<BarChartLegend>
			{Children.map(children, (child) => (
				<BarChartLegendItem color={child.props.color}>
					{child.props.label}
				</BarChartLegendItem>
			))}
		</BarChartLegend>
		<Chart highestValue={highestValue}>
			<StyledBarChartBackground />
			<Bars>
				{Children.map(children, (child) => (
					<ChartBar
						value={child.props.value}
						color={child.props.color}
					/>
				))}
			</Bars>
		</Chart>
	</Container>
)

export default BarChart
