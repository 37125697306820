import React, { useContext, useState, useCallback, useEffect } from 'react'
import { Formik } from 'formik'
import SkeletonLoader from '@components/UI/SkeletonLoader'

import {
	GetUserAccountOverviewDocument,
	useEditUserAccountMutation,
	useGetReactivateUserAccountQuery
} from '@graphql/graphql'

import { ReactivateUserAccountStore } from '@contexts/ReactivateUserAccountContext'
import { validationSchema } from '@contexts/ReactivateUserAccountContext/Validation'
import { useLocale } from '@hooks/useLocale'
import { useNotificationContext } from '@contexts/NotificationContext'
import { NotificationTypes } from '@contexts/NotificationContext/types'

import FormFooter from '@components/UI/Forms/FormFooter'
import { FormTitle } from '@components/UI/Forms/FormHeader'
import { FormFieldHorizontal, FormRow } from '@components/UI/Forms/FormUI'
import { SnackbarNotificationTypes } from '@components/UI/Snackbar/types'
import SidebarForm from '@components/SidebarForm'

import {
	FormButtonRightCol,
	FormButtonWrapper
} from '@components/UI/Forms/FormFooter/styles'

import useError from '@hooks/useError'
import ErrorState from '@components/UI/Error'
import GeneralFormFields from './components/GeneralFormFields'
import { mapValues } from './helpers/mapValues'

import { ReactivateUserAccountFormProps } from './types'

const ReactivateUserAccountForm = ({
	onFormSubmit
}: ReactivateUserAccountFormProps) => {
	// Contexts
	const {
		initialValues,
		loading: contextLoading,
		error: contextError
	} = useContext(ReactivateUserAccountStore)
	const { sendNotification } = useNotificationContext()
	const { locale } = useLocale()
	const { showError } = useError()

	// Local state
	const [sending, setSending] = useState(false)
	const [intialized, setInitialized] = useState(false)

	// APIs
	const [editUserAccountMutation] = useEditUserAccountMutation({
		refetchQueries: [{ query: GetUserAccountOverviewDocument }]
	})

	const { data, loading, error } = useGetReactivateUserAccountQuery({
		variables: {
			language: locale
		}
	})

	const content = data?.GlobalItem?.content?.global[0]
	const validation = content?.validation[0]?.messages[0]

	// Event handlers
	const initialiseHandler = useCallback(async () => {
		if (!intialized && !loading && content) {
			setInitialized(true)
		}
	}, [content, intialized, loading])

	const submitHandler = useCallback(
		async (values) => {
			setSending(true)

			const formValues = mapValues(values, initialValues.companies)

			try {
				const request = await editUserAccountMutation({
					variables: {
						request: formValues
					}
				})

				if (request.data) {
					const id = request?.data?.editUserAccount.id

					if (id) {
						sendNotification(
							NotificationTypes.SNACKBAR,
							{
								snackbarType: SnackbarNotificationTypes.SUCCESS,
								title:
									initialValues?.email &&
									content?.submitSuccess
										? content?.submitSuccess.replace(
												'[VALUE]',
												initialValues?.email
										  )
										: '[The user is updated]'
							},
							{
								showAfterDelay: 2000
							}
						)
					} else {
						showError(
							content?.submitError ||
								'[There was an error. Please try again later]'
						)
					}

					onFormSubmit()
				}
			} catch (requestError) {
				setSending(false)
				showError(
					content?.submitError ||
						'[There was an error. Please try again later]'
				)
				onFormSubmit()
			}
		},
		[
			content?.submitError,
			content?.submitSuccess,
			editUserAccountMutation,
			initialValues.companies,
			initialValues?.email,
			onFormSubmit,
			sendNotification,
			showError
		]
	)

	useEffect(() => {
		initialiseHandler()
	}, [initialiseHandler])

	if (error || contextError) {
		return (
			<SidebarForm footer={null}>
				{/* This error is displayed when fteching content from the CMS gives an error, therefore we cannot get a translated error message */}
				<ErrorState
					content="Something went wrong while retrieving the form"
					showBowWave
				/>
			</SidebarForm>
		)
	}

	if ((!intialized && loading) || contextLoading) {
		const companiesArray = [1, 2, 3, 4]
		const authorizationArray = [1, 2]

		return (
			<SidebarForm
				footer={
					<FormButtonWrapper>
						<SkeletonLoader width={160} height={48} />
						<FormButtonRightCol>
							<SkeletonLoader width={90} height={48} />
						</FormButtonRightCol>
					</FormButtonWrapper>
				}
			>
				<FormTitle>
					<SkeletonLoader width="50%" height={32} />
				</FormTitle>
				<FormFieldHorizontal>
					<FormRow>
						<SkeletonLoader />
						<SkeletonLoader width="100%" height={56} />
					</FormRow>
				</FormFieldHorizontal>
				<FormFieldHorizontal>
					<FormRow>
						{companiesArray.map((item) => (
							<span key={item}>
								<SkeletonLoader />
							</span>
						))}
					</FormRow>
				</FormFieldHorizontal>
				<FormFieldHorizontal>
					<FormRow>
						{authorizationArray.map((item) => (
							<span key={item}>
								<SkeletonLoader />
							</span>
						))}
					</FormRow>
				</FormFieldHorizontal>
			</SidebarForm>
		)
	}

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			onSubmit={submitHandler}
			validationSchema={validationSchema(validation)}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{({ errors, handleSubmit, setFieldValue, values }) => {
				return (
					<SidebarForm
						footer={
							<FormFooter
								labelDiscard={content?.buttonBack}
								labelSaving={content?.stateSaving}
								labelSubmit={content?.buttonSubmit}
								isSending={sending}
								isLastStep
								submitColor="orange"
								onBack={onFormSubmit}
							/>
						}
						onSubmit={handleSubmit}
					>
						<GeneralFormFields
							content={content}
							errors={errors}
							setFieldValue={setFieldValue}
							values={values}
						/>
					</SidebarForm>
				)
			}}
		</Formik>
	)
}

export default ReactivateUserAccountForm
