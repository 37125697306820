import React, { useCallback, useState, useEffect } from 'react'

import {
	useGetUserAccountOverviewLazyQuery,
	GetUserAccountOverviewQuery,
	GetUserAccountOverviewQueryVariables,
	useGetUserAccountStatusItemsQuery
} from '@graphql/graphql'

import { useLocale } from '@hooks/useLocale'
import { getStartsFromLocale } from '@utils/getStartsFromLocale'

import GenericOverview2 from '@components/GenericOverview2'
import UserAccountModal from '@components/Modals/UserAccountModal'
import { UserAccountModalType } from '@components/Modals/UserAccountModal/types'
import ErrorState from '@components/UI/Error'

import { useUserAccountContext } from '@contexts/UserAccountContext'
import Sidebar from '@components/Sidebar'
import StatusExplanation from '@components/UI/StatusExplanation'
import Spacer from '@components/Spacer'
import StatusExplanationSkeleton from '@skeletons/StatusExplanation'
import GenericOverviewSkeleton from '@skeletons/GenericOverview'
import { DefaultGenericError } from '@components/GenericOverview2/components/GenericError/DefaultGenericError'
import { Content } from '@components/UI/StatusExplanation/styles'
import { UserAccountOverviewProps } from './types'
import UserAccountOverviewTable from './UserAccountOverviewTable'
import UserAccountOverviewFilters, {
	UserAccountFilters
} from './UserAccountOverviewFilters'

const UserAccountOverview = (props: UserAccountOverviewProps) => {
	// Cached values
	const { blok } = props
	const { locale } = useLocale()
	const [load] = useGetUserAccountOverviewLazyQuery()
	const {
		data: userAccountStatuses,
		loading,
		error
	} = useGetUserAccountStatusItemsQuery({
		variables: {
			language: locale,
			starts_with: getStartsFromLocale(locale)
		}
	})

	const pageSize = 100

	// Context
	const { isOpen, closePanel: closeContextPanel } = useUserAccountContext()

	// State
	const [showStatusPanel, setShowStatusPanel] = useState(false)

	const [isPanelOpen, setIsPanelOpen] = useState({
		open: false,
		mode: UserAccountModalType.Invite,
		userAccountId: ''
	})

	const userAccountStatusInfo =
		userAccountStatuses?.UseraccountstatusItems?.items ?? []

	const iconClickHandler = useCallback(() => {
		setShowStatusPanel(true)
	}, [setShowStatusPanel])

	const closePanel = useCallback(() => {
		setIsPanelOpen({ open: false, mode: null, userAccountId: null })
		closeContextPanel()
	}, [setIsPanelOpen, closeContextPanel])

	useEffect(() => {
		if (isOpen) {
			setIsPanelOpen({
				open: true,
				mode: UserAccountModalType.Invite,
				userAccountId: ''
			})
		}
	}, [isOpen, setIsPanelOpen])

	const setEditMode = useCallback(() => {
		setIsPanelOpen({
			...isPanelOpen,
			mode: UserAccountModalType.Edit
		})
	}, [setIsPanelOpen, isPanelOpen])

	const setReactivateMode = useCallback(() => {
		setIsPanelOpen({
			...isPanelOpen,
			mode: UserAccountModalType.Reactivate
		})
	}, [setIsPanelOpen, isPanelOpen])

	return (
		<>
			<Sidebar
				isOpen={showStatusPanel}
				onClose={() => setShowStatusPanel(false)}
			>
				{error && !loading && (
					<Content>
						<ErrorState
							content={
								blok?.accountStatusErrorDescription ??
								'Something went wrong while retrieving account statuses information'
							}
							showBowWave
						/>
					</Content>
				)}
				{loading && !error && <StatusExplanationSkeleton rows={3} />}
				{!error && !loading && userAccountStatusInfo.length > 0 && (
					<StatusExplanation
						items={userAccountStatusInfo}
						title={blok.statusTitle ?? '[Status Explanation]'}
					/>
				)}
			</Sidebar>
			<UserAccountModal
				type={isPanelOpen.mode}
				userAccountId={isPanelOpen.userAccountId}
				isOpen={isPanelOpen.open}
				onClose={closePanel}
				setEditMode={setEditMode}
				setReactivateMode={setReactivateMode}
			/>
			<GenericOverview2<
				GetUserAccountOverviewQueryVariables,
				GetUserAccountOverviewQuery,
				UserAccountFilters
			>
				loadData={useCallback(
					({ page, filters }) =>
						load({
							variables: {
								language: locale,
								company: filters.company,
								skip: (page - 1) * pageSize,
								limit: pageSize
							}
						}),
					[load, locale]
				)}
				getTotalPages={(data) => data.userAccounts.total / pageSize}
				shouldRenderHeader={({ data, searching }) => {
					return (
						searching ||
						// hasData check should be having facets so you can always switch
						data?.userAccounts?.facets?.company.length > 0
					)
				}}
				renderHeader={({ data, filters, onChangeFilters }) => {
					// If no filter is selected, fake that the first one is selected
					if (!(filters.company?.length > 0)) {
						// eslint-disable-next-line no-param-reassign
						filters.company =
							data.userAccounts.facets.company[0].name
					}
					return (
						<Spacer paddingBottom={false}>
							<UserAccountOverviewFilters
								facets={data.userAccounts.facets}
								activeFilters={filters}
								companyFilterLabel={blok.filterCompany}
								onChangeFilters={onChangeFilters}
							/>
						</Spacer>
					)
				}}
				renderTable={({ data, orderBy, setOrderBy }) => (
					<UserAccountOverviewTable
						items={data.userAccounts.items}
						orderBy={orderBy}
						onOrderByChange={setOrderBy}
						statusColumnTitle={blok.tableStatus}
						nameColumnTitle={blok.tableName.split('/')[0]}
						emailColumnTitle={blok.tableName.split('/')[1]}
						createdDateColumnTitle={blok.tableDateCreated}
						lastSignInDateColumnTitle={blok.tableLastSignInDate}
						setIsPanelOpen={setIsPanelOpen}
						onIconClick={iconClickHandler}
					/>
				)}
				renderError={({ type, searchQuery }) => (
					<DefaultGenericError
						type={type}
						searchQuery={searchQuery}
						translations={blok}
					/>
				)}
				skeleton={<GenericOverviewSkeleton filterAmount={0} />}
			/>
		</>
	)
}

export default UserAccountOverview
