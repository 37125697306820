import { EditUserAccountRequest, UserAccountCompany } from '@graphql/graphql'

export function mapValues(
	values: any,
	accountCompanies: UserAccountCompany[]
): EditUserAccountRequest {
	return {
		id: values?.userAccountId,
		companies: accountCompanies.map((company) => company.id),
		accessRight: values?.authorization
	} as EditUserAccountRequest
}
