import React, { FC } from 'react'

import { UserAccountDetailStoryblok } from '@graphql/storyblokcomponents'

import { DataListRowProps } from './types'

export const mapContent = (blok: UserAccountDetailStoryblok) => {
	return {
		...blok
	}
}

export const DataListRow: FC<DataListRowProps> = ({
	labelSingle,
	labelMultiple,
	values = []
}) => {
	const html = values.join('<br/>')
	const { length } = values

	if (length > 0) {
		return (
			<>
				<dt>{length === 1 ? labelSingle : labelMultiple}</dt>
				<dd dangerouslySetInnerHTML={{ __html: html }} />
			</>
		)
	}

	return <></>
}
