import React from 'react'

import { AssetFilters } from '@components/AssetOverview/types'
import { GetAssetOverviewQuery } from '@graphql/graphql'
import { SearchField } from '@damen/ui'
import TwoColRow from '@components/TwoColRow'
import SelectFacets from '@components/SelectFacets'
import ActiveFacets from '@components/ActiveFacets'
import StickyFilters from '@components/StickyFilters'

interface FiltersProps {
	facets: GetAssetOverviewQuery['assets']['facets']
	activeFilters: AssetFilters
	onChangeFilters: (filters: AssetFilters) => void
	vesselTypeFilterLabel: string
	searchQuery: string
	setSearchQuery: (searchQuery: string) => void
	searchPlaceholder: string
}

const AssetOverviewFilters = ({
	facets: facetsData,
	activeFilters,
	onChangeFilters,
	vesselTypeFilterLabel,
	searchQuery,
	setSearchQuery,
	searchPlaceholder
}: FiltersProps) => {
	const facets = [
		{
			key: 'vesselTypes' as const,
			label: vesselTypeFilterLabel,
			options: facetsData?.vesselType || []
		}
	]

	return (
		<StickyFilters>
			<TwoColRow
				mobileLayout="columnReverse"
				spacerBorderBottom={false}
				paddingBottom={false}
				left={
					<SelectFacets
						facets={facets}
						activeFilters={activeFilters}
						onChangeFilters={onChangeFilters}
					/>
				}
				right={
					<SearchField
						value={searchQuery}
						onChange={setSearchQuery}
						placeholder={searchPlaceholder}
					/>
				}
			/>

			<ActiveFacets
				facets={facets}
				activeFilters={activeFilters}
				onChangeFilters={onChangeFilters}
			/>
		</StickyFilters>
	)
}

export default AssetOverviewFilters
