import React, { useContext, useMemo } from 'react'

import { ReactivateUserAccountStore } from '@contexts/ReactivateUserAccountContext'

import useScrollToFieldError from '@hooks/useScrollToFieldError'

import FieldRadioGroup from '@components/UI/Forms/FieldRadioGroup'
// import FieldCheckboxGroup from '@components/UI/Forms/FieldCheckboxGroup'
import FormHeader from '@components/UI/Forms/FormHeader'
import {
	FormFieldHorizontal,
	FormLabel,
	FormRow,
	FormText,
	FormTip
} from '@components/UI/Forms/FormUI'

import { GeneralReactivateUserAccountProps } from './types'

const REQUIRED_FORM_FIELDS = ['authorization']

const GeneralFormFields = ({
	content,
	errors,
	setFieldValue
}: GeneralReactivateUserAccountProps) => {
	// Context
	const { initialValues } = useContext(ReactivateUserAccountStore)

	// Cached properties
	const {
		title,
		labelEmail,
		labelEmailTip,
		labelAuthorization,
		labelCompany,
		labelCompanyTip,
		labelPrimaryCompany,
		labelPrimaryCompanyTip
	} = content

	const { fullName, email, allAuthorization, primaryCompany, companies } =
		initialValues
	const memoizedAuthorization = useMemo(
		() =>
			allAuthorization
				?.map((accessRight) => {
					return {
						key: accessRight.id,
						value: accessRight.name,
						description: accessRight.description
					}
				})
				.sort((a, b) => (a.value > b.value ? 1 : -1)),
		[allAuthorization]
	)

	useScrollToFieldError(errors, REQUIRED_FORM_FIELDS)

	return (
		<>
			<FormHeader
				title={`${title} ${fullName}` ?? '[Reactivate account:]'}
			/>
			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FormLabel htmlFor="email">{labelEmail}</FormLabel>
					<FormText>{email}</FormText>
					<FormTip>{labelEmailTip}</FormTip>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FormLabel htmlFor="primaryCompany">
						{labelPrimaryCompany ?? '[Primary Company]'}
					</FormLabel>
					<FormText>{primaryCompany}</FormText>
					<FormTip>{labelPrimaryCompanyTip}</FormTip>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow>
					<FormLabel htmlFor="company">
						{labelCompany ?? '[Company]'}
					</FormLabel>
					{companies.map((company) => (
						<FormText key={company.id}>{company.name}</FormText>
					))}
					<FormTip>{labelCompanyTip}</FormTip>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow>
					<FieldRadioGroup
						name="authorization"
						label={labelAuthorization ?? '[Authorization]'}
						options={memoizedAuthorization}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
		</>
	)
}

export default GeneralFormFields
