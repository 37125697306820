import React, { useCallback } from 'react'

import {
	AssetOrder,
	GetAssetOverviewQuery,
	GetAssetOverviewQueryVariables,
	useGetAssetOverviewLazyQuery
} from '@graphql/graphql'
import { useLocale } from '@hooks/useLocale'
import GenericOverview2 from '@components/GenericOverview2'
import AssetOverviewSkeleton from '@skeletons/AssetOverview'
import { SortingType } from '@components/GenericOverview/types'

import { DefaultGenericError } from '@components/GenericOverview2/components/GenericError/DefaultGenericError'
import { AssetColumnKeys, AssetFilters, AssetOverviewProps } from './types'
import AssetOverviewFilters from './AssetOverviewFilters'
import AssetOverviewTable from './AssetOverviewTable'
import { OverviewWrapper } from './styles'

const AssetOverview = ({ blok }: AssetOverviewProps) => {
	const { locale } = useLocale()
	const [load] = useGetAssetOverviewLazyQuery()

	const pageSize = 20

	return (
		<OverviewWrapper>
			<GenericOverview2<
				GetAssetOverviewQueryVariables,
				GetAssetOverviewQuery,
				AssetFilters
			>
				loadingText={blok.loadingContent}
				loadType="infinite"
				loadData={useCallback(
					({ filters, searchQuery, orderBy, page }) =>
						load({
							variables: {
								language: locale,
								vesselTypes: filters.vesselTypes,
								searchText: searchQuery,
								skip: (page - 1) * pageSize,
								limit: pageSize,
								order: [
									(!orderBy ||
										orderBy[AssetColumnKeys.vessel] ===
											SortingType.asc) &&
										AssetOrder.NameAsc,
									orderBy?.[AssetColumnKeys.vessel] ===
										SortingType.desc && AssetOrder.NameDesc,
									orderBy?.[
										AssetColumnKeys.deliveryDateFormatted
									] === SortingType.asc &&
										AssetOrder.DeliveryDateAsc,
									orderBy?.[
										AssetColumnKeys.deliveryDateFormatted
									] === SortingType.desc &&
										AssetOrder.DeliveryDateDesc
								].filter(Boolean)
							}
						}),
					[load, locale]
				)}
				mergeData={(prev, next) => ({
					...prev,
					assets: {
						...prev.assets,
						items: [...prev.assets.items, ...next.assets.items]
					}
				})}
				getTotalPages={(data) => data.assets.total / pageSize}
				renderHeader={({
					data,
					filters,
					onChangeFilters,
					searchQuery,
					setSearchQuery
				}) => (
					<AssetOverviewFilters
						facets={data.assets.facets}
						activeFilters={filters}
						vesselTypeFilterLabel={blok.filterType}
						onChangeFilters={onChangeFilters}
						searchQuery={searchQuery}
						setSearchQuery={setSearchQuery}
						searchPlaceholder={blok.searchPlaceholder}
					/>
				)}
				renderTable={({ data, searchWord, orderBy, setOrderBy }) => (
					<AssetOverviewTable
						items={data.assets.items}
						searchWord={searchWord}
						orderBy={orderBy}
						onOrderByChange={setOrderBy}
						nameColumnTitle={blok.tableVessel}
						yardNumberColumnTitle={blok.tableYardNumber}
						typeColumnTitle={blok.tableType}
						deliveryDateColumnTitle={blok.tableDeliveryDate}
						favouriteColumnTitle={blok.tableFavourite}
					/>
				)}
				renderError={({ type, searchQuery }) => (
					<DefaultGenericError
						type={type}
						searchQuery={searchQuery}
						translations={blok}
					/>
				)}
				skeleton={<AssetOverviewSkeleton />}
			/>
		</OverviewWrapper>
	)
}

export default AssetOverview
