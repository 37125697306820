import React, { useCallback, useContext, useMemo } from 'react'

import { EditUserAccountStore } from '@contexts/EditUserAccountContext'

import useScrollToFieldError from '@hooks/useScrollToFieldError'

import FieldRadioGroup from '@components/UI/Forms/FieldRadioGroup'
import FieldCheckboxGroup from '@components/UI/Forms/FieldCheckboxGroup'
import FormHeader from '@components/UI/Forms/FormHeader'
import {
	FormFieldHorizontal,
	FormLabel,
	FormRow,
	FormText,
	FormTip
} from '@components/UI/Forms/FormUI'

import GenericOverview2 from '@components/GenericOverview2'
import {
	GetCompaniesQuery,
	GetCompaniesQueryVariables,
	UserAccountCompanyOrder
} from '@graphql/graphql'
import { mapCompany } from '@contexts/InviteUserAccountContext/helpers'
import StickyFilters from '@components/StickyFilters'
import FormSearchField from '@components/UI/FormSearchField'
import { DefaultGenericError } from '@components/GenericOverview2/components/GenericError/DefaultGenericError'
import { CheckboxGroupError } from '@components/UI/Forms/FieldCheckbox/styles'
import UserAccountCompaniesSkeleton from '@skeletons/UserAccountCompanies'
import { LoadableCompaniesWrapper } from '../../InviteUserAccountForm/styles'
import { GeneralEditUserAccountProps } from './types'

const REQUIRED_FORM_FIELDS = ['email', 'companies', 'authorization']

const GeneralFormFields = ({
	content,
	errors,
	setFieldValue
}: GeneralEditUserAccountProps) => {
	// Context
	const { initialValues, loadCompanies } = useContext(EditUserAccountStore)
	const companiesPageSize = 12

	// Cached properties
	const {
		title,
		labelEmail,
		labelEmailTip,
		labelAuthorization,
		labelCompany,
		labelCompanyTip,
		labelPrimaryCompany,
		labelPrimaryCompanyTip,
		emptyTitle,
		emptyContent,
		searchNotFoundTitle,
		searchNotFoundContent,
		errorTitle,
		errorContent,
		errorButtonContent,
		placeholderSearchCompany
	} = content

	const { fullName, email, allAuthorization, primaryCompany } = initialValues
	const memoizedAuthorization = useMemo(
		() =>
			allAuthorization
				?.map((accessRight) => {
					return {
						key: accessRight.id,
						value: accessRight.name,
						description: accessRight.description
					}
				})
				.sort((a, b) => (a.value > b.value ? 1 : -1)),
		[allAuthorization]
	)

	useScrollToFieldError(errors, REQUIRED_FORM_FIELDS)

	return (
		<>
			<FormHeader title={`${title} ${fullName}` ?? '[Edit account:]'} />
			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FormLabel htmlFor="email">{labelEmail}</FormLabel>
					<FormText>{email}</FormText>
					<FormTip>{labelEmailTip}</FormTip>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FormLabel htmlFor="primaryCompany">
						{labelPrimaryCompany ?? '[Primary Company]'}
					</FormLabel>
					<FormText>{primaryCompany}</FormText>
					<FormTip>{labelPrimaryCompanyTip}</FormTip>
				</FormRow>
			</FormFieldHorizontal>
			{/* Company Access dropdown */}
			<FormFieldHorizontal>
				<FormRow>
					<LoadableCompaniesWrapper>
						<GenericOverview2<
							GetCompaniesQueryVariables,
							GetCompaniesQuery,
							null
						>
							loadType="infinite"
							loadData={useCallback(
								({ searchQuery, page }) =>
									loadCompanies({
										variables: {
											searchText: searchQuery,
											skip:
												(page - 1) * companiesPageSize,
											limit: companiesPageSize,
											order: UserAccountCompanyOrder.NameDesc
										}
									}),
								[loadCompanies, companiesPageSize]
							)}
							mergeData={(prev, next) => ({
								...prev,
								manageableCompanies: {
									...prev.manageableCompanies,
									items: [
										...prev.manageableCompanies.items,
										...next.manageableCompanies.items
									]
								}
							})}
							getTotalPages={(data) =>
								data.manageableCompanies.total /
								companiesPageSize
							}
							renderHeader={({ searchQuery, setSearchQuery }) => (
								<StickyFilters>
									<FormLabel htmlFor="companies">
										{labelCompany ?? '[Company access]'}
									</FormLabel>
									<FormSearchField
										value={searchQuery}
										onChange={setSearchQuery}
										placeholder={placeholderSearchCompany}
									/>
								</StickyFilters>
							)}
							renderTable={({ data }) => (
								<FieldCheckboxGroup
									name="companies"
									hideLabel
									label={labelCompany ?? '[Company access]'}
									options={data.manageableCompanies.items.map(
										(item) => mapCompany(item, null)
									)}
									setFieldValue={setFieldValue}
								/>
							)}
							renderError={({ type, searchQuery }) => (
								<DefaultGenericError
									type={type}
									searchQuery={searchQuery}
									translations={{
										emptyTitle,
										emptyContent,
										searchNotFoundTitle,
										searchNotFoundContent,
										errorTitle,
										errorContent,
										errorButtonContent
									}}
								/>
							)}
							skeleton={<UserAccountCompaniesSkeleton />}
						/>
					</LoadableCompaniesWrapper>
					{errors.companies !== '' &&
						errors.companies !== undefined && (
							<CheckboxGroupError>{`${
								labelCompany ?? '[Company access]'
							} ${errors.companies}`}</CheckboxGroupError>
						)}
					<FormTip>{labelCompanyTip}</FormTip>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow>
					<FieldRadioGroup
						name="authorization"
						label={labelAuthorization ?? '[Authorization]'}
						options={memoizedAuthorization}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
		</>
	)
}

export default GeneralFormFields
